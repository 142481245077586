import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Location } from '@angular/common';
import * as moment from "moment";
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  private _isLoadingtable$ = new BehaviorSubject<boolean>(false);
  private _isCategory$ = new BehaviorSubject<boolean>(false);
  private _isLoadingcategory$ = new BehaviorSubject<boolean>(false)
  private _filterSource = new BehaviorSubject<any>([]);
  private _isLoadingUsers$ = new BehaviorSubject<any>([]);
  filter$ = this._filterSource.asObservable();
  private apiUrl = environment.apiUrl;
  private googleauth = environment.googleauth;

  constructor(private http: HttpClient,
    private location: Location) { }

  get isLoading$() {
    return this._isLoading$.asObservable();
  }

  get isLoadingUsers$() {
    return this._isLoadingUsers$.asObservable();
  }


  get isLoadingcategory$() {
    return this._isLoadingcategory$.asObservable();
  }
  get isLoadingtable$() {
    return this._isLoadingtable$.asObservable();
  }

  get isCategory$() {
    return this._isCategory$.asObservable();
  }

  getFilters() {
    return this._filterSource.getValue();
  }

  setFilters(filter): void {
    this._filterSource.next(filter);
  }

  getActiveChannel() {

    return this.http.get(`${this.apiUrl}/channel`);
  }


  getallusers(params) {
    this._isLoadingUsers$.next(true);
    return this.http.post(`${this.apiUrl}/general/userlist`, params).pipe(
      finalize(() => {
        this._isLoadingUsers$.next(false)
      })
    );
  }

  getAutoComplete(params) {
    return this.http.post(`${this.apiUrl}/audio/autocompelete`, params)
  }

  getall(params) {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/general/account`, params).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }

  adminsList() {
    return this.http.post(`${this.apiUrl}/breakup/row/admin`, {});
  }
  audiolist() {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/general/audio`, { search: "", type: "1" }).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }

  videolist(param) {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/video/dropdown`, param).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }

  polllist() {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/notification/poll/post`, { type: 2 }).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }
  postlist() {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/notification/poll/post`, { type: 1 }).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }
  audiolisttype() {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/general/audio`, { search: "", type: "2" }).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }

  playlist() {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/general/playlist`, { search: "", type: "1" }).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }


  getBackets() {
    return this.http.get(`${this.apiUrl}/general/bucket/list`);
  }



  artistlist() {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/general/artist`, { search: "", type: "1" }).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }

  getSpeaker() {
    return this.http.post(`${this.apiUrl}/general/artist`, { search: "", type: "1", speaker_creator_type: "1" })
  }
  getCreator() {
    return this.http.post(`${this.apiUrl}/general/artist`, { search: "", type: "1", speaker_creator_type: "2" })
  }
  getArtistList() {
    this._isLoadingtable$.next(true);
    return this.http.get(`${this.apiUrl}/creator/artist/list`).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }





  getclip(rights_holder = "") {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/general/clip`, { rights_holder }).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }
  getallcategory(perent?) {
    this._isLoadingtable$.next(true);
    return this.http.get(`${this.apiUrl}/post/poll/category`).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }

  getcategories() {
    this._isLoadingcategory$.next(true);
    return this.http.get(`${this.apiUrl}/post/poll/category`).pipe(
      finalize(() => {
        this._isLoadingcategory$.next(false)
      })
    );
  }

  getAllcategories() {
    this._isLoadingcategory$.next(true);
    return this.http.get(`${this.apiUrl}/category`).pipe(
      finalize(() => {
        this._isLoadingcategory$.next(false)
      })
    );
  }
  getAudioList(params) {

    return this.http.post(`${this.apiUrl}/channel/audio/get`, params);
  }

  getSubCategory(perent?) {
    this._isCategory$.next(true);
    return this.http.patch(`${this.apiUrl}/general/sub/category/${perent}`, {}).pipe(
      finalize(() => {
        this._isCategory$.next(false)
      })
    );
  }
  getSkillCategory(perent?) {
    this._isCategory$.next(true);
    return this.http.get(`${this.apiUrl}/user/skill/category`).pipe(
      finalize(() => {
        this._isCategory$.next(false)
      })
    );
  }
  getallenergyleavel(params) {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/general/energylist`, params).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );

  }

  creatorrightholder(params) {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/general/creator/rights/holder`, params).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );

  }

  getallchannel(params) {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/general/channel`, params).pipe(

      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }

  getallartist() {
    return this.http.get(`${this.apiUrl}/general/artist`);
  }

  getallartistnew() {
    return this.http.get(`${this.apiUrl}/artist/all`);
  }
  getallcontracts(params) {
    return this.http.post(`${this.apiUrl}/general/contract`, params);
  }

  getactivecontract(params) {
    return this.http.post(`${this.apiUrl}/general/rights/holders/youtube/contract`, params);
  }

  verifychennal(params) {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/general/channel/info`, params).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }

  accesstoken(channel_id, json) {
    return this.http.put(`${this.apiUrl}/channel/access-token/${channel_id}`, json);
  }

  getAccessToken(code, redirect_uri) {
    return this.http.post(`${this.apiUrl}/channel/google/access-token`, {
      code,
      redirect_uri
    })
  }

  getGoogleAuth(redirect_uri, state = "") {
    return this.http.post(`${this.apiUrl}/channel/google/code`, { redirect_uri, state });
  }

  getLabelFromDate(startDate, endDate) {
    let label = "";
    if (startDate && endDate) {
      const start = moment(startDate).format("MMM DD,YYYY")
      const end = moment(endDate).format("MMM DD,YYYY")

      if (startDate == moment().subtract(6, "days").format('YYYY-MM-DD') && endDate == moment().format('YYYY-MM-DD')) {
        label = "Last 1 Week - (" + start + " - " + end + ")";
      } else if (startDate == moment().format('YYYY-MM-DD') && endDate == moment().format('YYYY-MM-DD')) {
        label = "Today";
      } else if (startDate == moment().subtract(1, "days").format('YYYY-MM-DD') && endDate == moment().subtract(1, "days").format('YYYY-MM-DD')) {
        label = "Yesterday";
      } else if (startDate == moment().subtract(27, "days").format('YYYY-MM-DD') && endDate == moment().format('YYYY-MM-DD')) {
        label = "Last 28 Days - (" + start + " - " + end + ")";
      } else if (startDate == moment().subtract(89, "days").format('YYYY-MM-DD') && endDate == moment().format('YYYY-MM-DD')) {
        label = "Last 90 Days - (" + start + " - " + end + ")";
      } else if (startDate == moment().subtract(364, "days").format('YYYY-MM-DD') && endDate == moment().format('YYYY-MM-DD')) {
        label = "Last 365 Days - (" + start + " - " + end + ")";
      } else if (startDate == moment().subtract(1, "month").startOf("month").format('YYYY-MM-DD') && endDate == moment().subtract(1, "month").endOf("month").format('YYYY-MM-DD')) {
        label = "Last 1 Month - (" + start + " - " + end + ")";
      } else if (startDate == moment().subtract(3, "month").startOf("month").format('YYYY-MM-DD') && endDate == moment().subtract(1, "month").endOf("month").format('YYYY-MM-DD')) {
        label = "Last 3 Month - (" + start + " - " + end + ")";
      } else if (startDate == moment().subtract(6, "month").startOf("month").format('YYYY-MM-DD') && endDate == moment().subtract(1, "month").endOf("month").format('YYYY-MM-DD')) {
        label = "Last 6 Month - (" + start + " - " + end + ")";
      } else if (startDate == moment().subtract(12, "month").startOf("month").format('YYYY-MM-DD') && endDate == moment().subtract(1, "month").endOf("month").format('YYYY-MM-DD')) {
        label = "Last 12 Month - (" + start + " - " + end + ")";
      } else {
        label = start + " - " + end;
      }
    }

    return label;

  }

  convertToInternationalCurrencySystem(labelValue) {

    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

      ? (Math.round((Math.abs(Number(labelValue)) * 100) / 100) / 1.0e+9) + "B"
      // Six Zeroes for Millions 
      : Math.abs(Number(labelValue)) >= 1.0e+6

        ? (Math.round((Math.abs(Number(labelValue)) * 100) / 100) / 1.0e+6) + "M"
        // Three Zeroes for Thousands
        : Math.abs(Number(labelValue)) >= 1.0e+3

          ? (Math.round((Math.abs(Number(labelValue)) * 100) / 100) / 1.0e+3) + "K"

          : Math.abs(Number(labelValue));

  }

}
